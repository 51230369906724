<template>
  <v-container
    fill-height
    fluid
  >
    <v-row>
      <v-col align="center">
        <div>
          <v-alert
            outlined
            type="info"
            border="top"
            max-width="500"
          >
            Modulet for Retningslinjer er foreløbig ikke aktiveret
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Guidelines",
};
</script>